<template>
    <div class="info-box">
        <HomePanel title="公司介绍">
            <p class="comdis"> 北京国信融信科技产业有限公司2018年6月成立于北京，是中国国信信息总公司旗下的全资子公司。
            中国国信信息总公司隶属于国家信息中心（国家发展和改革委员会直属单位）。
            国信融信致力于为党、政、军、企、校提供高效、优质的科技服务，业务范围包含全球商网、供应链科技、大宗贸易、IDC建设、智慧城市、新能源等六大板块，
            国信融信已在上海、广州、深圳、青岛、成都等8个城市设立分支机构，公司员工100余人，其中拥有15年以上行业经验的技术团队30余人。
            国信融信与清华、北大、中科院、北理工等高校及科研机构紧密合作，业务伙伴包括中国联通、中国电信、中国移动、阿里巴巴、腾讯、百度、微软等全球多家大型企业.</p>
            <div style="padding:30px 20px;">
                <img src="../../assets/images/infoimg.jpg" />
            </div>
        </HomePanel>

    </div>
</template>
<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import HomePanel from "./../home/components/home-panel.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    name: "companyinfo",
    components: { HomePanel },
     data() {
      return {
        
      };
    },
    methods: {

    }
};
</script>
<style lang="less">
.comdis{
    text-indent:24px;
    line-height:25px;
    font-size:16px;
}
</style>